const hostPath = 'https://leonardo.test.keldysh.io';

export const environment = {
  hostPath: hostPath,
  apiPath: hostPath + '/api/leonardo-service',
  firebaseConfig: {
    apiKey: "AIzaSyCic0fntOwcHxNyZp6Bxn5yrFzhUTEbshc",
    authDomain: "leonardo-staging-716ae.firebaseapp.com",
    projectId: "leonardo-staging-716ae",
    storageBucket: "leonardo-staging-716ae.appspot.com",
    messagingSenderId: "764812379931",
    appId: "1:764812379931:web:0f453109b6be693b38f5be"
  },
  production: false
};
